<script setup lang="ts">
const props = defineProps<{
	id?: string
	label?: string
	name?: string
	modelValue?: boolean
	disabled?: boolean
	indeterminate?: boolean
}>();

const emit = defineEmits(['update:modelValue', 'change']);

const id = props.id ?? useId();

const isChecked = ref(props.modelValue || false);

const handleChange = () => {
	isChecked.value = !isChecked.value;
	emit('update:modelValue', isChecked.value);
	emit('change', isChecked.value);
};

watch(() => props.modelValue, (checked) => {
	isChecked.value = checked;
});
</script>

<template>
	<div class="form-switch">
		<input
			:indeterminate="indeterminate"
			type="checkbox"
			:name="props.name"
			:disabled="props.disabled"
			:checked="isChecked"
			:id="id"
			v-bind="$attrs"
			@change="handleChange"
		/>
		<label :for="id">
			<span class="aria:sr">{{ props.label }}</span>
		</label>
		<span class="form-switch__indicator">
			<span class="form-switch__indicator-on">{{ $t('props.on') }}</span>
			<span class="form-switch__indicator-off">{{ $t('props.off') }}</span>
			<span class="form-switch__indicator-na">{{ $t('props.na') }}</span>
		</span>
	</div>
</template>
